import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "guider"
    }}>{`Guider`}</h1>
    <p>{`Här samlar vi alla de guider och artiklar vi har som handlar om att köpa
elcyklar och vad just du ska tänka på. Vi hjälper dig navigera bland
krångliga begrepp och ger dig den kunskap som vi tror att du behöver för
att kunna hitta just den elcykeln som är pefekt för just dina behov.`}</p>
    <ul>
      <li parentName="ul">
        <Link to="motorplacering/" mdxType="Link">Motorplacering</Link>
      </li>
      <li parentName="ul">
        <Link to="elcykelbatterier/" mdxType="Link">Elcykelbatterier</Link>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      